@font-face {
  font-family: 'GenesisSans Text';
  font-style: normal;
  font-weight: 400;
  src: url('GenesisSansTextOffice-Regular.woff2') format('woff2');
}
@font-face {
  font-family: 'GenesisSans Text';
  font-style: normal;
  font-weight: 500;
  src: url('GenesisSansTextOffice-Medium.woff2') format('woff2');
}
@font-face {
  font-family: 'GenesisSans Head';
  font-style: normal;
  font-weight: 300;
  src: url('GenesisSansHeadOffice-Light.woff2') format('woff2');
}
@font-face {
  font-family: 'GenesisSans Head';
  font-style: normal;
  font-weight: 400;
  src: url('GenesisSansHeadOffice-Regular.woff2') format('woff2');
}


@font-face {
  font-family: 'GenesisSans Text KR';
  font-style: normal;
  font-weight: 400;
  src: url('GenesisSansTextKRRegular.woff2') format('woff2');
}
@font-face {
  font-family: 'GenesisSans Text KR';
  font-style: normal;
  font-weight: 500;
  src: url('GenesisSansTextKRMedium.woff2') format('woff2');
}
@font-face {
  font-family: 'GenesisSans Head KR';
  font-style: normal;
  font-weight: 300;
  src: url('GenesisSansHeadKRLight.woff2') format('woff2');
}
@font-face {
  font-family: 'GenesisSans Head KR';
  font-style: normal;
  font-weight: 400;
  src: url('GenesisSansHeadKRRegular.woff2') format('woff2');
}