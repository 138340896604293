.hide_view {
    opacity: 0 !important;
}

.option-container .swiper-wrapper{
    pointer-events: none;
}

.menu .menu-bottom .btn > span::before {
    width: 0;
    margin-right: 0;
}

.menu .menu-bottom .btn.menu-save > span::before {
    background: none !important;
}
.menu .menu-bottom .btn.menu-load > span::before {
    background: none !important;
}

body {
    background-color: #000000 !important;
}


/*image picker custom pointer*/
.imgpicker-wrap .ImagePicker[src^=data] ~ .centerPoint {
    display: block;
}

.imgpicker-wrap .centerPoint {
    display: none;
    position: absolute;
    top: 0px;
    left: 0px;
    pointer-events: none;
}

.imgpicker-wrap .imagePointer {
    display: block !important;
    top:-12.5px !important;
    left:-12.5px !important;
}
/*image picker custom pointer*/


[class*=preselection-arrow-] {
    position: relative;
}
[class*=preselection-arrow-]::after {
    content: "";
    position: absolute;
    top: 50%;
    margin-top: -4px;
    display: block;
    width: 8px;
    height: 8px;
    border-top: 1px solid #4d4d4d;
    border-right: 1px solid #4d4d4d;
}

.preselection-arrow-right::after {
    right: 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.saturation-white:nth-child(2) > div > div {
    position: absolute;
    top: -8px;
    left: -8px;
}

.sketch-picker {
    /*cursor: none;*/
}

.imgpicker-wrap .ImagePicker:not([src^=data]) {
    visibility: inherit !important;
}


.model-info .btn {
    min-height: 49.75px;
}
.summary-btngroup .loadingView, .model-select .loadingView {
    background-color: rgba(255,255,255,0) !important;
}
.summary-btngroup .loadingView .spinner, .model-select .loadingView .spinner, .model-info .btn .loadingView .spinner {
    width: 30px !important;
    height: 30px !important;
}
.summary-btngroup .loadingView .spinner::after, .model-select .loadingView .spinner::after, .model-info .btn .loadingView .spinner::after {
    width: 30px !important;
    height: 30px !important;
    border-width: 5px !important;
    opacity: 1 !important;
}

.model-mobile .swiper-pagination .swiper-pagination-bullet {
    width: 8px !important;
    height: 8px !important;
}

.model-mobile .swiper-pagination .swiper-pagination-bullet-active {
    width: 10px !important;
    height: 10px !important;
}


.aside-open {
    -webkit-transform: translateX(0) !important;
    transform: translateX(0) !important;
}

.mainView {
    width: 100vw !important;
    height: 100vh !important
}

.mainView > canvas {
    /*width: 100% !important;*/
    /*height: 100% !important;*/
    width: inherit !important;
    height: inherit !important;
    margin-top: 0!important;
}



@media (hover: hover) {
    .menu .menu-bottom .btn:hover.menu-save > span::before {
        background-image: none !important;
    }
    .menu .menu-bottom .btn:hover.menu-load > span::before {
        background-image: none !important;
    }
}

@media only screen and (orientation: portrait) {
    .mainView {
        padding-top: 0 !important;
        height: 70vw !important;
    }
    .mainView > canvas {
        height: 100% !important
    }
}